export class StorageUtils {
  getForm(): string {
    throw new Error('Method not implemented.');
  }

  public storeToken(token: string) {
    localStorage.setItem('token', token);
  }


  public getToken() {
    return localStorage.token
  }

  public idTokenHint(idTokenHint: string) {
    localStorage.setItem('idTokenHint', idTokenHint);
  }


  public getIdTokenHint() {
    return localStorage.idTokenHint
  }
  
  public setTokenArk(tokenArk: string) {
    localStorage.setItem('tokenArk', tokenArk);
  }

  public getTokenArk() {
    return localStorage.tokenArk
  }

  public idUser(idUser: string) {
    localStorage.setItem('idUser', idUser);
  }

  public getIdUser() {
    return localStorage.idUser
  }

  public nameUser(nameUser: string) {
    localStorage.setItem('nameUser', nameUser);
  }

  public getNameUser() {
    return localStorage.nameUser
  }

  public idObj(idObj: string) {
    localStorage.setItem('idObj', idObj);
  }

  public getIdObj() {
    return localStorage.idObj
  }

  public urlHome(urlHome: string) {
    localStorage.setItem('urlHome', urlHome);
  }

  public getUrlHome() {
    return localStorage.urlHome
  }

  public idEvent(idEvent: string) {
    localStorage.setItem('idEvent', idEvent);
  }

  public getIdEvent() {
    return localStorage.idEvent
  }

  public idCompany(idCompany: string) {
    localStorage.setItem('idCompany', idCompany);
  }

  public getIdCompany() {
    return localStorage.idCompany
  }

  public roleId(roleId: string) {
    localStorage.setItem('roleId', roleId);
  }

  public getRoleId() {
    return localStorage.roleId
  }
}
